import React, { useEffect, useState } from 'react';
import { Box, Container, TextField, Button, Typography } from '@mui/material';
import SafexLogo from './safelogo.png';
import axios from 'axios';

function App() {
  const [input1, setInput1] = useState('');
  const [input3, setInput3] = useState('');
  const [miningHashrate, setMiningHashrate] = useState('');
  const [miningReward, setMiningReward] = useState('');
  const [fetchStakedTokensPercentage, setStakedTokensPercentage] = useState('Loading...');
  const [networkHashrate, setNetworkHashrate] = useState('Loading...');
  const [networkDifficulty, setNetworkDifficulty] = useState('Loading...');
  const [blockReward, setBlockReward] = useState('Loading...');
  
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'input1') {
      setInput1(addCommas(removeNonNumeric(value)));
    } else if (name === 'input3') {
      setInput3(addCommas(removeNonNumeric(value)));
    } else if (name === 'miningHashrateInput') {
      setMiningHashrate(value);
    }
  };

  const [result, setResult] = useState('');


  useEffect( () => {
    async function fetchStakedTokens() {
      const stakedPercentageResponse = await axios.get('https://safex.ninja/staked_amount')
      const stakedPercentage = stakedPercentageResponse.data.staked_amount / 1885974016 * 100;
      setStakedTokensPercentage(stakedPercentage.toFixed(2));
    }

    async function fetchNetworkInfo() {
      const response = await axios.get('https://pool.safex.org/api/live_stats')
      const totalHashrate = (response.data.network.difficulty / 120 / 1000000).toFixed(2);
      setNetworkHashrate(totalHashrate);
      setNetworkDifficulty(response.data.network.difficulty);
      setBlockReward(response.data.lastblock.reward);
    }
    fetchStakedTokens();
    fetchNetworkInfo();
  }, []);
  
  const calculateResult = async () => {
    const yearlyTradeVolume = input1.replace(/,/g, '');
    const stakedTokens = input3.replace(/,/g, '');
    let calcResult = Number(yearlyTradeVolume) * Number(stakedTokens) * 0.05 / (fetchStakedTokensPercentage / 100 * 1885974016);
    calcResult = addCommas(removeNonNumeric(calcResult.toFixed(0)));
    setResult(calcResult);
  };

    // New simple calculation or action function
    const handleSimpleAction = () => {
      var profit = ((miningHashrate * 86400 / networkDifficulty) * blockReward / 10000000).toFixed(2);

      // Placeholder for whatever logic you want here. For demonstration, we'll just set the input as the result.
      setMiningReward(profit); // Directly setting the result as the input value
    };

  return (
 <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative', gap: '20px', padding: '20px 0' }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        style={{ border: '1px solid #ccc', padding: '40px', borderRadius: '10px', width: '70%', boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)', backgroundColor: 'white', zIndex: 2 }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          Revenue Share Calculator
        </Typography>
        <p style={{ alignSelf: 'flex-start', width: '100%', margin: '0 0 10px 0', fontSize: '14px', color: '#667', marginTop: 20 }}>
        Total % of staked SFT tokens on network: <b>{fetchStakedTokensPercentage}</b>
        </p>
        <TextField
          label="Estimated yearly trade volume in $"
          variant="outlined"
          name="input1"
          value={input1}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Amount of your staked SFT tokens"
          variant="outlined"
          name="input3"
          value={input3}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={calculateResult} style={{ marginTop: 20 }}>
          Calculate
        </Button>
        <Typography variant="h6" style={{ marginTop: 20 }}>
          Estimated revenue share $: {result}
        </Typography>
      </Box>

      <br></br>
      <Box
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        style={{ border: '1px solid #ccc', padding: '40px', borderRadius: '10px', width: '70%', boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)', backgroundColor: 'white', zIndex: 2 }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          Mining Calculator
        </Typography>
        <p style={{ alignSelf: 'flex-start', width: '100%', margin: '0 0 10px 0', fontSize: '14px', color: '#667', marginTop: 20 }}>
        Network Hashrate: <b>{networkHashrate} MH/s</b>
        </p>
        <TextField
          label="Enter your hashrate (KH/s)"
          variant="outlined"
          name="miningHashrateInput"
          value={miningHashrate}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={handleSimpleAction} style={{ marginTop: 20 }}>
          Calculate
        </Button>
        <Typography variant="h6" style={{ marginTop: 20 }}>
          SFX per day: {miningReward}
        </Typography>
      </Box>
      {/* <img src={SafexLogo} alt="Description" style={{
          position: 'relative',
          top: '0', // Adjust these values as needed to position the logo
          right: '0',
          zIndex: 1, // Ensure the image is behind the content
          maxWidth: '100%', // Optional, for responsive image sizing
          height: 'auto' // Optional, for responsive image sizing
        }} /> */}
    </Container>
  );
}

export default App;
